.App {
  text-align: center;
  width: 100%;
}

.App-intro {
  font-size: 2vw;
}

.appLogo {
  display: table;
  width: 100%;
  height: 100px;
}

.appLogo-semantic {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.appLogo img {
  max-width: 100%;
  height: auto;
  max-height: 250px;
}

.infoSection {
  margin: 10px 0;
  background-image: linear-gradient(#cdcdcd, #efefef);
  border-radius: 10px;
  padding: 1px 10px 5px 10px;
  min-height: 325px;
  max-height: 600px;
  box-shadow: 1px 1px #333;
}

.infoSection h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-bottom: 0;
  color: darkblue;
}

.infoSection h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin-bottom: 0;
  color: darkblue;
}

.infoSection h4 {
  font-family: 'Roboto', sans-serif;
  font-size: 12pt;
  font-weight: bold;
  margin: 0;
}

.infoSection p {
  font-family: 'Roboto', Helvetica, sans-serif;
}

.infoSection a {
  color: #080;
  cursor: pointer;
}

.infoSection a:hover {
  color: #00A;
}

.infoSection a:link {
  text-decoration: none;
}

.mainLogo {
  padding: 25px 0;
}

.mailingListLink {
  text-align: center;
  margin-top: 40px;
  display: none;
}
