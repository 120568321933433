.sanctioned {
    min-height: 180px;
    max-height: 600px;
    max-width: 350px;
    text-align: center;
    position: relative;
    left: 50%;
    margin-left: -160px;
}

.sanctioned h2 {
    font-size: 13pt;
}

.sanctioned p {
    font-size: 10pt;
}

.sanctionedImages {
    max-width: 400px;
}

.sanctionedImages img {
    max-width: 125px;
    min-width: 50px;
    padding: 5px;
    margin: 6px 3px 3px 3px;
    background-color: white;
}

@media only screen and (max-width: 400px) {
    .sanctioned {
        max-width: 325px;
        margin-left: -165px
    }
}

#icma {
    border-radius: 65px;
}

#ifcma {
    border-radius: 75px;
    padding-left: 0;
}