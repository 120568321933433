.apeDefense h2 {
    font-size: 16pt;
}

.apeDefense h3 {
    font-size: 14pt;
}

.apeDefense p {
    font-size: 10pt;
}

.apeDefense ul {
    list-style-type: none;
    font-size: 10pt;
    padding-left: 20px;
    margin-top: 0;
}

.apeDefense a {
    font-size: 10pt;
}
