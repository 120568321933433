.relatedLinks {
    padding: 1px 10px;
    min-height: 325px;
    max-height: 600px;
    font-size: 10pt;
    font-family: Arial, Helvetica, sans-serif;
}

.relatedLinks h2 {
    font-size: 16pt;
}

.relatedLinks h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10pt;
    font-weight: normal;
    color: black;
}

.relatedLinks ul {
    padding-left: 25px;
}
