.whatWeTeach {
    background-color: #DEDEDE;
    border-radius: 10px;
    padding: 1px 10px;
    min-height: 250px;
    max-height: 600px;
    font-size: 10pt;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

.whatWeTeach h2 {
    font-size: 16pt;
}

.whatWeTeach ul {
    margin-top: 0;
}

.whatWeTeach p, ul {
    font-size: 10pt;
}

.whatWeTeach button {
    margin-bottom: 10px;
}
