#App-Header-Main {
    background-image: linear-gradient(black, #222);
    color: white;
}

.appHeader {
    height: 80px;
    width: 100%;
}

.appHeader p {
    margin: 0;
    font-size: 10pt;
    color: #CCC;
}

.appHeader-title {
    font-size: 1.75em;
    margin-bottom: 0;
}

#homeLogo {
    float: left;
    margin-right: 10px;
    background-color: white;
    padding: 3px;
    cursor: pointer;
}

#homeLogo img {
    width: 55px;
}
