.locationDues {
    margin-top: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11pt;
}

.location ul {
    margin-top: 0;
}

.headInstructor {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10pt;
}

.locationAddress {
    margin-top: 10px;
    margin-bottom: 10px;
}

.locationAddress h4 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
    font-weight: bold;
    margin: 0;
}

.locationAddress h5 {
    margin: 0;
    font-size: 10pt;
}
